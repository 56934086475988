
import $ from 'jquery';
import AriaExpand from './objects/AriaExpand';

const selectors = {
	toggle : '.m-menu-dropdown__header',
	content : '.m-menu-dropdown__content',
}

const classes = {
	open : 'is-visible'
}

const expands = [];

function changeHandler(state, expand) {
	const open = (state === AriaExpand.OPEN);
	if (!open) return;
	expands
		.filter(item => {
			return (item !== expand) && (item.isOpen())
		})
		.forEach(item => item.close());
}

export const initInstance = function(el) {
	const toggle = el.querySelector(selectors.toggle);
	const content = el.querySelector(selectors.content);
	const expand = new AriaExpand(toggle, content, classes.open, changeHandler);
	expands.push(expand);
}
